import React, { useState } from "react"
import Container from "../../microcomponents/container/container"
import "./get-demov2.scss"
import {
  InputErrorIcon,
  InputValidIcon,
  SPZLOGOWHITE,
} from "../../microcomponents/icons"
import { Field, Form, Formik } from "formik"
import Button from "../../microcomponents/button/button"
export default function GetDemoV2({
  additionalClass = "",
  sendData = true,
  returnUrl = "/get-demo/",
}) {
  //   const navigate = useNavigate()
  const checkWindow = typeof window !== `undefined`
  let email = null
  let source = null
  let medium = null
  let campaign = null
  let content = null
  let device = null
  if (checkWindow) {
    const searchParams = new URLSearchParams(window.location.search)
    email = searchParams.get("email")
      ? decodeURIComponent(searchParams.get("email"))
      : ""
    source = searchParams.get("utm_source")
      ? decodeURIComponent(searchParams.get("utm_source"))
      : ""
    medium = searchParams.get("utm_medium")
      ? decodeURIComponent(searchParams.get("utm_medium"))
      : ""
    campaign = searchParams.get("utm_campaign")
      ? decodeURIComponent(searchParams.get("utm_campaign"))
      : ""
    content = searchParams.get("utm_content")
      ? decodeURIComponent(searchParams.get("utm_content"))
      : ""
    device = searchParams.get("device")
      ? decodeURIComponent(searchParams.get("device"))
      : ""
  }

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const formMessageEnableDisable = () => {
    setTimeout(() => {
      const checkwindowlintrk = typeof window !== `undefined`
      if (checkwindowlintrk && window?.lintrk) {
        window?.lintrk("track", { conversion_id: 6913844 })
      }
      document.querySelector("#demoformwrapper").style.display = "none"
      document.querySelector("#thankyoumessagewrapper").style.display = "block"
    }, 400)
  }

  const allValid = closestInputWrap => {
    closestInputWrap.classList.remove("error")
    closestInputWrap.classList.remove("focus")
    closestInputWrap.classList.add("valid")
  }

  const allError = closestInputWrap => {
    closestInputWrap.classList.add("error")
    closestInputWrap.classList.remove("focus")
    closestInputWrap.classList.remove("valid")
  }

  const handleBlur = (closestInputWrap, condition) => {
    if (!condition) {
      allError(closestInputWrap)
      return
    } else {
      allValid(closestInputWrap)
      return true
    }
  }

  return (
    <Container className={`get-demo-wrapper ${additionalClass}`}>
      <div className="get-demo-inner">
        <div className="get-demo-card">
          <div className="get-demo-header">
            <SPZLOGOWHITE stroke="#1854F9" />
          </div>
          <div className="get-demo-form" id="demoformwrapper">
            <h1> Get a Demo</h1>
            <p>
              During the demo we can help you estimate the upside, share some{" "}
              day-one test concepts, and answer any questions you may have.
            </p>
            <div className="get-demo-form-wrapper">
              <Formik
                initialValues={{
                  name: "",
                  email: email ?? "",
                }}
                onSubmit={values => {
                  for (let key in values) {
                    var closestInputWrap = document
                      .querySelector(`[name="${key}"]`)
                      .closest(".input-wrap")
                    if (
                      !handleBlur(
                        closestInputWrap,
                        values[key].trim() !== "" &&
                          (key === "email"
                            ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                values[key]
                              ) &&
                              [
                                "gmail",
                                "icloud",
                                "yahoo",
                                "outlook",
                                "icloud",
                                "aol",
                                "zoho",
                                "mail",
                                "protonmail",
                                "yandex",
                                "yopmail",
                              ].indexOf(
                                values[key].split("@")[1].split(".")[0]
                              ) === -1
                            : true)
                      )
                    ) {
                      return
                    }
                  }
                  if (sendData) {
                    fetch("/", {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                      },
                      body: encode({
                        "form-name": "get-demo-form-v2",
                        source,
                        medium,
                        campaign,
                        content,
                        device,
                        ...values,
                      }),
                    })
                      .then(() => {
                        formMessageEnableDisable()
                      })
                      .catch(error => alert(error))
                  } else {
                    formMessageEnableDisable()
                  }
                }}
              >
                {({ isSubmitting }) => (
                  <Form
                    className="form"
                    method="post"
                    name="get-demo-form-v2"
                    netlify-honeypot="bot-field"
                    data-netlify="true"
                  >
                    <Field type="hidden" name="bot-field" />
                    <Field
                      type="hidden"
                      name="form-name"
                      value="get-demo-form-v2"
                    />
                    <Field type="hidden" name="source" value={source} />
                    <Field type="hidden" name="medium" value={medium} />
                    <Field type="hidden" name="campaign" value={campaign} />
                    <Field type="hidden" name="content" value={content} />
                    <Field type="hidden" name="device" value={device} />
                    <div className={`input-wrap`}>
                      <label>
                        {" "}
                        Name
                        {<InputErrorIcon />}
                        {<InputValidIcon />}
                      </label>
                      <Field
                        type="text"
                        name="name"
                        placeholder="Name"
                        className="text-input"
                      >
                        {({ field }) => {
                          return (
                            <input
                              {...field}
                              placeholder="Name"
                              onFocus={e => {
                                e.currentTarget
                                  .closest(".input-wrap")
                                  .classList.add("focus")
                              }}
                              onBlur={e => {
                                handleBlur(
                                  e.currentTarget.closest(".input-wrap"),
                                  e.currentTarget.value.trim() !== ""
                                )
                              }}
                            />
                          )
                        }}
                      </Field>
                      <span className="input-error">
                        Please enter your name
                      </span>
                    </div>
                    <div className={`input-wrap`}>
                      <label>
                        {" "}
                        Email
                        {<InputErrorIcon />}
                        {<InputValidIcon />}
                      </label>
                      <Field
                        type="email"
                        name="email"
                        placeholder="Email"
                        className="text-input"
                      >
                        {({ field }) => {
                          return (
                            <input
                              {...field}
                              placeholder="Email"
                              onFocus={e => {
                                e.currentTarget
                                  .closest(".input-wrap")
                                  .classList.add("focus")
                              }}
                              onBlur={e => {
                                handleBlur(
                                  e.currentTarget.closest(".input-wrap"),
                                  e.currentTarget.value.trim() !== "" &&
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                      e.currentTarget.value
                                    ) &&
                                    [
                                      "gmail",
                                      "icloud",
                                      "yahoo",
                                      "outlook",
                                      "icloud",
                                      "aol",
                                      "zoho",
                                      "mail",
                                      "protonmail",
                                      "yandex",
                                      "yopmail",
                                    ].indexOf(
                                      e.currentTarget.value
                                        .split("@")[1]
                                        .split(".")[0]
                                    ) === -1
                                )
                              }}
                            />
                          )
                        }}
                      </Field>
                      <span className="input-error">
                        Please enter a valid email
                      </span>
                    </div>

                    <Button version={`v2`} icon={null}>
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className="get-demo-form" id="thankyoumessagewrapper">
            <h2>Your request has been sent!</h2>
            <p>
              We appreciate you contacting us. One of our colleagues <br /> will
              get back in touch with you soon!
            </p>
            <br />
            <p>
              For immediate assistance please call us at{" "}
              <span
                style={{
                  color: "#000F29",
                }}
              >
                888-677-4725
              </span>
            </p>
            <div className="get-demo-form-wrapper">
              <Button version={`v2`} href={returnUrl} icon={null} type="cta">
                Back to main page
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}
