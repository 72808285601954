import * as React from "react"
import Seo from "../components/seo2023"
import Layout from "../components/layout2023"
import GetDemoV2 from "../componentsv2/get-demov2/get-demov2"

const GetDemo = () => (
  <Layout nav={"hidden"} parentClass={`newtheme2024`}>
    <Seo title="Get A Demo" />
    <GetDemoV2 />
  </Layout>
)

export default GetDemo
